export const environment = {
    configuration: 'staging',
    apiUrl: 'https://api.kbcgame.com/api/v1',
    wsUrl: 'https://ws.kbcgame.com:2096/kbcgame',
    assetsUrl: 'https://imgx.kbcgame.com',
    CLOUDFLARE: {
        TURNSTILE: {
            SITE_KEY: `0x4AAAAAAAQb5whMPXvLNuc7`
        }
    },
    LIVECHAT: `18486033`,
    COUNTRY_CODE: [
        // { name: `Andorra`, code: `+376` },
        // { name: `United Arab Emirates (‫الإمارات العربية المتحدة‬‎)`, code: `+971` },
        // { name: `Antigua and Barbuda`, code: `+1268` },
        // { name: `Anguilla`, code: `+1264` },
        // { name: `Angola`, code: `+244` },
        // { name: `Antarctica`, code: `+672` },
        // { name: `Australia`, code: `+61` },
        // { name: `Aruba`, code: `+297` },
        // { name: `Barbados`, code: `+1246` },
        // { name: `Bolibia`, code: `+591` },
        // { name: `Belize`, code: `+501` },
        // { name: `Canada`, code: `+1` },
        { name: `South Korea (대한민국)`, code: `+82` },

    ],
    BANK_CODE: [
        { name: '', code: '' },
    ],
    BET_STATUS: {
        IN_PROGRESS: 'IN_PROGRESS', // 대기
        CANCELLED: 'CANCELLED',     // 취소
        WON: 'WON',                 // 정산완료(적중)
        LOSS: 'LOSS',               // 정산완료(실패)
        RETURN: 'RETURN',           // 적특
        CASHOUT: 'CASHOUT',         // 캐쉬 아웃
    },
    BET_STATUS_TEXT: {
        IN_PROGRESS: `진행중`,
        CANCELLED: `취소`,
        WON: `승리`,
        LOSS: `실패`,
        RETURN: `Return`,
        CASHOUT: `CashOut`
    },
    DEPOSIT_STATUS: {
        REQUEST: `REQUEST`,         // 신청
        IN_PROGRESS: `IN_PROGRESS`, // 입금대기중
        COMPLETED: `COMPLETED`,     // 완료
        CANCEL: `CANCEL`,           // 취소
    },
    DEPOSIT_STATUS_TEXT: {
        REQUEST: `신청`,
        IN_PROGRESS: `진행중`,
        COMPLETED: `완료`,
        CANCEL: `취소`
    },
    WITHDRAW_STATUS: {
        REQUEST: `REQUEST`,         // 신청
        IN_PROGRESS: `IN_PROGRESS`, // 심사중
        ACCEPT: `ACCEPT`,           // 검수완료
        COMPLETED: `COMPLETED`,     // 출금완료
        CANCEL: `CANCEL`,           // 취소
    },
    WITHDRAW_STATUS_TEXT: {
        REQUEST: `신청`,         // 신청
        IN_PROGRESS: `진행중`, // 심사중
        ACCEPT: `출금대기`,           // 검수완료
        COMPLETED: `출금완료`,     // 출금완료
        CANCEL: `취소`,           // 취소
    },
    MEMO_STATUS: {
        UNREAD: `UNREAD`,
        READ: `READ`
    },
    MEMO_STATUS_TEXT: {
        UNREAD: `읽지않음`,
        READ: `읽음`
    },
    LEVEL_IMAGE: {
        '': '',
        1: 'bronze.png',
        2: 'silver.png',
        3: 'gold.png',
        4: 'platinum.png',
        5: 'diamond.png'
    },
    LANG: [
        { code: 'en-IN', name:'Indian English', image:'', is_show:true, priority:0 },
        { code: 'en', name:'English', image:'', is_show:true, priority:0 },
        { code: 'vi', name:'Tiếng việt', image:'', is_show:true, priority:0 },
        { code: 'id', name:'Indonesian', image:'', is_show:true, priority:0 },
        { code: 'jp', name:'日本語', image:'', is_show:true, priority:0 },
        { code: 'ko', name:'한국어', image:'', is_show:true, priority:0 },
        { code: 'fr', name:'Français', image:'', is_show:true, priority:0 },
        { code: 'es', name:'Español', image:'', is_show:true, priority:0 },
        { code: 'tl', name:'Filipino', image:'', is_show:true, priority:0 },
        { code: 'ar', name:'عربى', image:'', is_show:true, priority:0 },
        { code: 'hi', name:'हिन्दी', image:'', is_show:true, priority:0 },
        { code: 'tr', name:'Türkçe', image:'', is_show:true, priority:0 },
        { code: 'fa', name:'فارسی', image:'', is_show:true, priority:0 },
        { code: 'pt', name:'Português', image:'', is_show:true, priority:0 },
        { code: 'ru', name:'Руccкий', image:'', is_show:true, priority:0 },
        { code: 'de', name:'Deutsch', image:'', is_show:true, priority:0 },
        { code: 'th', name:'ภาษาไทย', image:'', is_show:true, priority:0 },
        { code: 'fi', name:'Suomi', image:'', is_show:true, priority:0 },
        { code: 'pl', name:'Polski', image:'', is_show:true, priority:0 },
        { code: 'it', name:'Italiano', image:'', is_show:true, priority:0 },
        { code: 'mi', name:'မြန်မာ', image:'', is_show:true, priority:0 },
        { code: 'ur', name:'اردو', image:'', is_show:true, priority:0 },
        { code: 'uk', name:'Українська', image:'', is_show:true, priority:0 },
        { code: 'ms', name:'Melayu', image:'', is_show:true, priority:0 },
        { code: 'bn', name:'বাংলা', image:'', is_show:true, priority:0 },
        { code: 'mr', name:'Marathi', image:'', is_show:true, priority:0 },
        { code: 'ta', name:'Tamil', image:'', is_show:true, priority:0 },
        { code: 'te', name:'Telugu', image:'', is_show:true, priority:0 },
    ],
    CURRENCY: [
        { code: 'USD', name:'US Dollar', image:'USD.webp', is_major:true, is_show:true, priority:0 },
        { code: 'BRL', name:'Brazil', image:'BRL.webp', is_major:false, is_show:true, priority:0 },
        { code: 'INR', name:'India', image:'INR.webp', is_major:false, is_show:true, priority:0 },
        { code: 'EUR', name:'Euro', image:'EUR.webp', is_major:true, is_show:true, priority:0 },
        { code: 'PUR', name:'Russia', image:'RUB.webp', is_major:false, is_show:true, priority:0 },
        { code: 'NGN', name:'Nigeria', image:'NGN.webp', is_major:false, is_show:true, priority:0 },
        { code: 'INR', name:'India', image:'INR.webp', is_major:false, is_show:true, priority:0 },
        { code: 'BDT', name:'Bangladesh', image:'BDT.webp', is_major:false, is_show:true, priority:0 },
        { code: 'PHP', name:'Philippine', image:'PHP.webp', is_major:false, is_show:true, priority:0 },
        { code: 'VND', name:'Vietnam', image:'VND.webp', is_major:false, is_show:true, priority:0 },
        { code: 'THB', name:'Thailand', image:'THB.webp', is_major:true, is_show:true, priority:0 },
        { code: 'KZT', name:'Kazakhstani tenge', image:'KZT.webp', is_major:false, is_show:true, priority:0 },
        { code: 'MAD', name:'Morocco', image:'MAD.webp', is_major:false, is_show:true, priority:0 },
        { code: 'ZAR', name:'South Africa', image:'ZAR.webp', is_major:false, is_show:true, priority:0 },
        { code: 'PLN', name:'Poland', image:'PLN.webp', is_major:false, is_show:true, priority:0 },
        { code: 'CUP', name:'Cuba', image:'CUP.webp', is_major:false, is_show:true, priority:0 },
        { code: 'KRW', name:'Korea', image:'KRW.webp', is_major:true, is_show:true, priority:0 },
        { code: 'JPY', name:'Japan', image:'JPY.webp', is_major:true, is_show:true, priority:0 },
        { code: 'GBP', name:'United Kingdom', image:'GBP.webp', is_major:true, is_show:true, priority:0 },
        { code: 'HRK', name:'Croatia', image:'HRK.webp', is_major:false, is_show:true, priority:0 },
        { code: 'ISK', name:'Iceland', image:'ISK.webp', is_major:false, is_show:true, priority:0 },
        { code: 'HUF', name:'Hungary', image:'HUF.webp', is_major:false, is_show:true, priority:0 },
        { code: 'NOK', name:'Norwegian', image:'NOK.webp', is_major:false, is_show:true, priority:0 },
        { code: 'NZD', name:'New Zealand Dollar', image:'NZD.webp', is_major:false, is_show:true, priority:0 },
        { code: 'ARS', name:'Argentina Peso', image:'ARS.webp', is_major:false, is_show:true, priority:0 },
        { code: 'MXN', name:'Mexico Peso', image:'MXN.webp', is_major:false, is_show:true, priority:0 },
        { code: 'AUD', name:'Australia Dollar', image:'AUD.webp', is_major:true, is_show:true, priority:0 },
        { code: 'TRY', name:'Turkey Lira', image:'TRY.webp', is_major:false, is_show:true, priority:0 },
        { code: 'IRR', name:'Iran Rial', image:'IRR.webp', is_major:false, is_show:true, priority:0 },
        { code: 'AED', name:'UAE-Dirham', image:'AED.webp', is_major:false, is_show:true, priority:0 },
        { code: 'CAD', name:'Canada Dollar', image:'CAD.webp', is_major:true, is_show:true, priority:0 },
        { code: 'UAH', name:'Ukraine Hryvnia', image:'UAH.webp', is_major:false, is_show:true, priority:0 },
        { code: 'CZK', name:'Czech Republic Koruna', image:'CZK.webp', is_major:false, is_show:true, priority:0 },
        { code: 'LRK', name:'Sri Lanka Rupee', image:'LKR.webp', is_major:false, is_show:true, priority:0 },
        { code: 'ILS', name:'Israel Shekel', image:'ILS.webp', is_major:false, is_show:true, priority:0 },
        { code: 'EGP', name:'Egypt Pound', image:'EGP.webp', is_major:false, is_show:true, priority:0 },
        { code: 'PKR', name:'Pakistan Rupee', image:'PKR.webp', is_major:false, is_show:true, priority:0 },
        { code: 'GHS', name:'Ghana Cedi', image:'AED.webp', is_major:false, is_show:true, priority:0 },
        { code: 'VEF', name:'Venezuela Bolívar', image:'VEF.webp', is_major:false, is_show:true, priority:0 },
        { code: 'PEN', name:'Peru Sol', image:'PEN.webp', is_major:false, is_show:true, priority:0 },
        { code: 'RON', name:'Romania Leu', image:'RON.webp', is_major:false, is_show:true, priority:0 },
        { code: 'BGN', name:'Bulgaria Lev', image:'BGN.webp', is_major:false, is_show:true, priority:0 },
        { code: 'RSD', name:'Serbia Dinar', image:'RSD.webp', is_major:false, is_show:true, priority:0 },
        { code: 'CLP', name:'Chile Peso', image:'CLP.webp', is_major:false, is_show:true, priority:0 },
    ],
    CRYPTO: [
        { name: 'Fasttoken', code: 'FTN', network: ['BHRC-20'], dcode: ['FTNF'], provider:'fulgurpay', image: 'FTN.webp', is_show:true },
        { name: 'Bitcoin', code: 'BTC', network: ['Seqwit'], dcode: ['BTC'], provider:'fulgurpay', image: 'BTC.webp', is_show:true},
        { name: 'Ethereum', code: 'ETH', network: ['Seqwit'], dcode: ['ETH'], provider:'fulgurpay', image: 'ETH.webp', is_show:true },
        { name: 'Tether', code: 'USDT', network: ['ERC-20', 'TRC-20', 'BEP-20'], dcode: ['USDT', 'USDT_T', 'USDB'], provider:'fulgurpay', image: 'USDT.webp', is_show:true },
        { name: 'Tron', code: 'TRX', network: ['Seqwit'], dcode: ['TRX'], provider:'fulgurpay', image: 'TRX.webp', is_show:true },
        { name: 'USD Coin', code: 'USDC', network: ['Seqwit'], dcode: ['USDC'], provider:'fulgurpay', image: 'USDC.webp', is_show:true },
        { name: 'Binance Coin ', code:'BNB', network: ['ERC-20', 'BEP-20)'], dcode: ['BNB', 'BNBB'], provider:'fulgurpay', image: 'BNB.webp', is_show:true },
        { name: 'LTC', code: 'LTC', network: ['Seqwit'], dcode: ['LTC'], provider:'fulgurpay', image: 'LTC.webp', is_show:true },
        { name: 'DAI', code: 'DAI', network: ['Seqwit'], dcode: ['DAI'], provider:'fulgurpay', image: 'DAI.webp', is_show:true },
        { name: 'SHIBA INU', code: 'SHIB', network: ['Seqwit'], dcode: ['SHIB'], provider:'fulgurpay', image: 'SHIB.webp', is_show:true },
    ],
    WALLET_CODE: {
        KRW: `KRW`,
        POINT: `POINT`,
        CREDIT: `CREDIT`
    },
    GIPHY_ITEMS: [
        `https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExN3J6cmQxeGV5Nno1Y3VnenYwbjFkMXg3NGVqYXgxaTFzd3V2bWlyaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l41JWw65TcBGjPpRK/giphy.gif`,
        `https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnpvcnhoMjl4eXB6MDAxZzEwdzdzanU5M213aXhtYW5yc2x3M2pnZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/BYoRqTmcgzHcL9TCy1/giphy.gif`,
        `https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExc3hmODczYXJueXdmMGFtbnozZGhzaTF6YXFlcHZrZ3l5Y25wbHE0cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/K6WIhJ07gwGkIAQfwN/giphy.gif`,
        `/assets/images/good_bye.gif`,
        `https://media2.giphy.com/media/Z21HJj2kz9uBG/100.gif`,
        `https://media0.giphy.com/media/5K7ngCtszoxxbaBieC/100.gif`,
        `https://media0.giphy.com/media/fqfPOiwKagbluB8Ors/100.gif`,
        `https://media0.giphy.com/media/s7hDATWJusTO0rSkZh/100.gif`,
        `https://media1.giphy.com/media/ZOwljDSMNr1rV76DMc/100.gif`,
        `https://media0.giphy.com/media/RggaJCxJQZhsh0DziT/100.gif`,
        `https://media4.giphy.com/media/l0IybQ6l8nfKjxQv6/100.gif`,
        `https://media1.giphy.com/media/wsUuw16j6oyxLLRnnK/100.gif`,
        `https://media4.giphy.com/media/tsX3YMWYzDPjAARfeg/100.gif`,
        `https://media2.giphy.com/media/OjmrBW4ZQbWjkq6RkC/100.gif`,
        `https://media1.giphy.com/media/ljuSksqL9j0yI/100.gif`,
        `https://media2.giphy.com/media/AVAMdwgvxi7WU/100.gif`,
        `https://media4.giphy.com/media/J2WQhnfK2WuUE/100.gif`,
        `https://media3.giphy.com/media/MTGFTtypKDV8kZyzr9/100.gif`
    ]
};

export type environmentOptions = {
    [ key: string ] : string | number | undefined;
}    