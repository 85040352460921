import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

    private subs: Subscription[] = [];
    
    private current = new BehaviorSubject<any>(''); // 시간을 sync 한다.
    everySecond: Observable<number> = timer(0, 100);

    constructor() { 
        this.current.next(moment.tz('Asia/Seoul'));

        const sub = this.everySecond.subscribe((seconds) => {
            this.current.next(moment.tz('Asia/Seoul'));
        });

        this.subs.push(sub);
    }

    public getDate() {
        return this.current.asObservable() 
    }

    public setDate(date:string, tz:string='Asia/Seoul') {
        this.current.next(moment.tz(date, tz));
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }
}
