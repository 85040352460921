import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, } from 'rxjs/operators';
import { environment } from '../environments/environment'
import { TimerService } from './timer.service'
import { LoadingService } from '../common/loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    apiUrl:string = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private timerService: TimerService,
        private loadingService: LoadingService,
    ) { }

    get(method:any, loading:boolean = true) {
        if(loading) this.loadingService.setLoading(true)
        return this.http.get<any>(this.apiUrl + method).pipe(
            map(jsondata => {
                if(!jsondata) throw Error('Value expected!');
                if(jsondata.timestamp) this.timerService.setDate(jsondata.timestamp);
                if(loading) this.loadingService.setLoading(false) 
                return jsondata;
            }), 
            catchError(err => {
                if(loading) this.loadingService.setLoading(false) 
                return throwError(() => err)
            })
        )
    }

    post(method:any, params:any = {}, loading:boolean = true) {
        if(loading) this.loadingService.setLoading(true)
        return this.http.post<any>(this.apiUrl + method, params).pipe(
            map(jsondata => {
                if(!jsondata) throw Error('Value expected!');
                if(jsondata.timestamp) this.timerService.setDate(jsondata.timestamp);
                if(loading) this.loadingService.setLoading(false) 
                return jsondata;
            }), 
            catchError(err => {
                if(loading) this.loadingService.setLoading(false)
                return throwError(() => err)
            })
        )
    }
}
